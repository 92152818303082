import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/components/render-from-template-context.js");
